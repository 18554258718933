<template>
  <div class="surveyParticipationList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :exportUrl="exportUrl"
      exportPermission
      :exportBefore="exportLimit"
      :extraParams="extraParams"
    >
      <template #headerSlot>
           <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="参与人姓名" v-model="searchParams.joinUserName"></v-input>
        <v-input label="参与人手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select label="身份" v-model="searchParams.userType" :options="userTypeOps"></v-select>
        <v-input label="房号信息" v-model="searchParams.roomNumber"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="reginParams"></v-select2>
        <v-select label="是否中奖" v-model="searchParams.isLuckyDog" :options="isLuckyDogOps"></v-select>
        <v-input label="中奖奖品" v-model="searchParams.awardName"></v-input>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text"  @click="edit(scope.row)" ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, exportLimitURL } from './api'
import { setStatusOps, statusMap, setUserTypeOps, userTypeMap, setIsLuckyDogOps, isLuckyDogMap } from './map'
import { communityParams, regionParams } from 'common/select2Params'
export default {
  name: 'surveyParticipationList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: setStatusOps(1),
      userTypeOps: setUserTypeOps(1),
      isLuckyDogOps: setIsLuckyDogOps(1),
      searchParams: {
        joinUserName: '',
        mobileNum: '',
        userType: undefined,
        roomNumber: '',
        communityId: '',
        regionId: '',
        isLuckyDog: undefined,
        awardName: '',
        status: undefined
      },
      extraParams: {
        voteId: ''
      },
      communityParams: communityParams,
      reginParams: regionParams,
      headers: [
        {
          prop: 'userName',
          label: '参与人姓名'
        }, {
          prop: 'mobileNum',
          label: '参与人手机号'
        }, {
          prop: 'userTypeTxt',
          label: '身份',
          formatter (row) {
            return userTypeMap[row.userType]
          }
        }, {
          prop: 'roomNumber',
          label: '房号信息'
        }, {
          prop: 'communityName',
          label: '所属项目'
        }, {
          prop: 'regionName',
          label: '所属公司'
        }, {
          prop: 'isLuckyDogTxt',
          label: '是否中奖',
          formatter (row) {
            return isLuckyDogMap[row.isLuckyDog]
          }
        }, {
          prop: 'awardName',
          label: '中奖奖品'
        }, {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        }, {
          prop: 'updateUserName',
          label: '操作人'
        }, {
          prop: 'updateTime',
          label: '操作时间'
        }
      ]
    }
  },
  created () {
    if (this.$route.query.id) {
      this.extraParams.voteId = this.$route.query.id
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'surveyParticipationForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'surveyParticipationForm',
        query: {
          id: row.id
        }
      })
    },
    goBack () {
      this.$router.push({
        name: 'satisfactionRateSurvey1'
      })
    },
    async exportLimit () {
      let isLimit = false
      let res = await this.$axios.get(exportLimitURL)
      if (res.status === 100) {
        isLimit = true
      }
      return isLimit
    }
  }
}
</script>
