import { mapHelper } from 'common/utils'

const status = [{
  text: '待审核',
  value: 0
}, {
  text: '已审核',
  value: 1
}, {
  text: '作废',
  value: 2
}]
const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

const userType = [{
  text: '用户',
  value: 1
}, {
  text: '家属',
  value: 2
}, {
  text: '租客',
  value: 3
}, {
  text: '伙伴',
  value: 4
}, {
  text: '嘉宾',
  value: 5
}]
const {
  map: userTypeMap,
  setOps: setUserTypeOps
} = mapHelper.setMap(userType)

const isLuckyDog = [{
  text: '是',
  value: 1
}, {
  text: '否',
  value: 0
}]
const {
  map: isLuckyDogMap,
  setOps: setIsLuckyDogOps
} = mapHelper.setMap(isLuckyDog)

export {
  setStatusOps,
  statusMap,
  setUserTypeOps,
  userTypeMap,
  setIsLuckyDogOps,
  isLuckyDogMap
}
