// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}questionnaire/joinuser/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}questionnaire/joinuser/list/export`
// 导出限制
const exportLimitURL = `${API_CONFIG.butlerBaseURL}export/limit`
// 获取详情
const queryURL = `${API_CONFIG.butlerBaseURL}questionnaire/joinuser/detail`
// 保存分类
const saveURL = `${API_CONFIG.butlerBaseURL}questionnaire/joinuser/audit`

export {
  getListURL,
  queryURL,
  exportLimitURL,
  exportListURL,
  saveURL
}
